
import { defineComponent } from 'vue'
import { isNotNullOrUndefined } from '../../../../utils/commonUtils'

export default defineComponent({
  name: 'IOPagePerformanceImpressionsAndCurrency',
  props: ['performance', 'ioDatastore', 'period'],
  methods: {
    formatNumbers (nb: number): string {
      if (!isNotNullOrUndefined(nb) || isNaN(nb)) {
        return '-'
      }
      if (Math.abs(nb) < 1000) {
        return (nb).toFixed(2).toString()
      }
      if (Math.abs(nb) < 1000000) {
        // if the number is a round thousand, we don't want to display the decimal
        if (Math.floor(nb / 1000) * 10 === Math.floor(nb / 100)) {
          return Math.floor(nb / 1000).toString() + 'K'
        }
        return Math.floor(nb / 100) / 10 + 'K'
      }
      if (Math.abs(nb) < 1000000000) {
        // if the number is a round million, we don't want to display the decimal
        if (Math.floor(nb / 10000000) * 100 === Math.floor(nb / 100000)) {
          return Math.floor(nb / 1000000).toString() + 'M'
        }
        return Math.floor(nb / 10000) / 100 + 'M'
      }
      if (Math.abs(nb) >= 1000000000) {
        // if the number is a round billion, we don't want to display the decimal
        if (Math.floor(nb / 10000000000) * 100 === Math.floor(nb / 100000000)) {
          return Math.floor(nb / 1000000000).toString() + 'B'
        }
        return (nb / 1000000000).toFixed(2) + 'B'
      }
    }
  },
  computed: {
    kpiFromPeriod (): any {
      switch (this.period) {
        case 'day':
          return this.performance?.kpi?.yesterday
        case 'week':
          return this.performance?.kpi?.last_7_days
        case 'flight':
          return this.performance?.kpi?.flight
        default:
          return this.performance?.kpi?.yesterday
      }
    },
    impressions (): any {
      return this.formatNumbers(this.kpiFromPeriod?.impressions)
    },
    currency (): any {
      return this.formatNumbers(this.kpiFromPeriod?.spent)
    }
  }
})
