
import { defineComponent } from 'vue'
import { isNotNullOrUndefined } from '../../../../utils/commonUtils'
import CopyClipboardComponent from '@/components/Common/CopyClipboardComponent.vue'

export default defineComponent({
  name: 'IOPagePerformanceSecondaryKpi',
  components: { CopyClipboardComponent },
  props: ['performance', 'ioDatastore', 'period'],
  methods: {
    formatNumbers (nb: number): string {
      if (!isNotNullOrUndefined(nb) || isNaN(nb)) {
        return '-'
      }
      if (Math.abs(nb) < 1000) {
        return (nb).toFixed(2).toString()
      }
      if (Math.abs(nb) < 1000000) {
        // if the number is a round thousand, we don't want to display the decimal
        if (Math.floor(nb / 1000) * 10 === Math.floor(nb / 100)) {
          return Math.floor(nb / 1000).toString() + 'K'
        }
        return Math.floor(nb / 100) / 10 + 'K'
      }
      if (Math.abs(nb) < 1000000000) {
        // if the number is a round million, we don't want to display the decimal
        if (Math.floor(nb / 10000000) * 100 === Math.floor(nb / 100000)) {
          return Math.floor(nb / 1000000).toString() + 'M'
        }
        return Math.floor(nb / 10000) / 100 + 'M'
      }
      if (Math.abs(nb) >= 1000000000) {
        // if the number is a round billion, we don't want to display the decimal
        if (Math.floor(nb / 10000000000) * 100 === Math.floor(nb / 100000000)) {
          return Math.floor(nb / 1000000000).toString() + 'B'
        }
        return (nb / 1000000000).toFixed(2) + 'B'
      }
    },
    getClassMajorInfo (value: string): string {
      return value?.length > 14 ? 'major-info-sm' : 'major-info'
    }
  },
  computed: {
    kpiFromPeriod (): any {
      if (this.complementaryKpiType === 'frequency') {
        return this.performance?.kpi?.flight
      }
      switch (this.period) {
        case 'day':
          return this.performance?.kpi?.yesterday
        case 'week':
          return this.performance?.kpi?.last_7_days
        case 'flight':
          return this.performance?.kpi?.flight
        default:
          return this.performance?.kpi?.yesterday
      }
    },
    kpiFromPreviousPeriod (): any {
      if (this.complementaryKpiType === 'frequency') {
        return this.performance?.kpi?.flight
      }
      switch (this.period) {
        case 'day':
          return this.performance?.kpi?.yesterday_minus_1
        case 'week':
          return this.performance?.kpi?.last_7_days_minus_1
        case 'flight':
          return this.performance?.kpi?.flight_minus_1
        default:
          return this.performance?.kpi?.yesterday_minus_1
      }
    },
    getKpiToOptimize (): any {
      return isNotNullOrUndefined(this.kpiFromPeriod?.kpi_to_optimize) ? this.kpiFromPeriod.kpi_to_optimize : '-'
    },
    getComplementaryKpiValue (): any {
      return isNotNullOrUndefined(this.kpiFromPeriod?.complementary_kpi_value) ? (this.kpiFromPeriod.complementary_kpi_value) : '-'
    },
    kpiComparedToPrevious (): any {
      return this.formatNumbers((this.kpiFromPreviousPeriod?.complementary_kpi_value - this.getComplementaryKpiValue) / this.kpiFromPreviousPeriod?.complementary_kpi_value * 100) + '%'
    },
    kpiCompareIcon (): any {
      const currentValue = this.kpiFromPeriod?.complementary_kpi_value
      const prevValue = this.kpiFromPreviousPeriod?.complementary_kpi_value
      return prevValue > currentValue ? 'arrow_drop_up' : prevValue < currentValue ? 'arrow_drop_down' : 'remove'
    },
    showPixels (): boolean {
      return ['conversions', 'conversions_post_click'].includes(this.complementaryKpiType)
    },
    pixels (): any {
      return this.ioDatastore?.conversion_funnel_pixel_ids
    },
    firstStepPixels (): any {
      return this.pixels['1']
    },
    complementaryKpiType (): any {
      return this.performance?.kpi?.flight?.complementary_kpi_type
    },
    complementaryKpiName (): any {
      return this.kpiFromPeriod?.complementary_kpi_display_name
    }
  },
  watch: {
  }
})
