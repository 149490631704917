import ConstraintsModel from './ConstraintsModel'
import { BriefApiArgs, Currency, Note, MeasurementTool } from '../../../types/brief_type'
import { BudgetType, KpiValue, RevenueType } from '../../../types/brief_enum'

export default class BriefModel {
  id: number = null
  minCpm: number = null
  maxCpm: number = null
  revCPM: number = null
  KPI?: KpiValue = null
  budgetToSaturateInPriority?: BudgetType = null
  text: string = ''
  constraints: ConstraintsModel = new ConstraintsModel()
  lineItemBudgetRestrictions = false
  timePartingRestrictions = false
  comments: string = ''
  boostTarget: number = null
  boostTargetUnit: Currency | '%' | '' = null
  conversionMeasurementTool?: MeasurementTool = null
  revenueType?: RevenueType = null
  minMargin: number
  commentBudgetMicro: string = ''
  commentBusinessModel: string = ''
  commentMarginConstraints: string = ''
  commentMainKpi: string = ''
  commentMainConstraints: string = ''
  commentViewRate: string = ''
  commentCompletionRate: string = ''
  commentFrequencyCap: string = ''
  commentFrequencyTarget : string = ''
  commentViewabilitySettings: string = ''
  commentBudgetRepartition: string = ''
  commentTimeParting: string = ''
  commentFoldPosition: string = ''
  commentCreativeActivity: string = ''
  commentFunnel: string = ''
  commentAutomaticallyRemoveDeprecatedUrl: string = ''
  commentOverwriteBidAdjustmentsOnDevice: string = ''
  commentAllowUnknownDemographic: string = ''
  commentPredictiveClearing: string = ''
  maxCPC: number = null
  maxCPCV: number = null
  currency: Currency |'' = null
  insertionDate: Date = null
  fitScoreAtSubmissionTime: number = null
  swid: string
  contactMailCopilot: string
  fromTemplateBriefId: number

  notes: Note[] = []

  constructor (budgetToSaturateInPriority: BudgetType = null, briefApiObject: BriefApiArgs = null) {
    if (briefApiObject !== null) {
      this.id = briefApiObject.id
      this.minCpm = briefApiObject.min_cpm
      this.maxCpm = briefApiObject.max_cpm
      this.revCPM = briefApiObject.rev_CPM
      this.KPI = briefApiObject.KPI
      this.budgetToSaturateInPriority = briefApiObject.budget_to_saturate_in_priority
      this.text = briefApiObject.text
      this.lineItemBudgetRestrictions = briefApiObject.line_item_budget_restrictions
      this.comments = briefApiObject.comments
      this.conversionMeasurementTool = briefApiObject.conversion_measurement_tool
      this.boostTarget = briefApiObject.boost_target
      this.constraints = briefApiObject.constraints ? new ConstraintsModel(
        briefApiObject.constraints.view_rate,
        briefApiObject.constraints.completion_rate,
        briefApiObject.constraints.frequency_cap_period,
        briefApiObject.constraints.frequency_cap_amount,
        briefApiObject.constraints.frequency_cap_exposure,
        briefApiObject.constraints.frequency_target_period,
        briefApiObject.constraints.overwrite_predictive_clearing,
        briefApiObject.constraints.frequency_target_amount,
        briefApiObject.constraints.frequency_target_exposure,
        briefApiObject.constraints.viewability_settings,
        briefApiObject.constraints.budget_repartition,
        briefApiObject.constraints.time_parting,
        briefApiObject.constraints.fold_position,
        briefApiObject.constraints.creative_activity,
        briefApiObject.constraints.allow_view_rate,
        briefApiObject.constraints.allow_completion_rate,
        briefApiObject.constraints.allow_frequency_cap,
        briefApiObject.constraints.allow_frequency_target,
        briefApiObject.constraints.automatically_remove_deprecated_url,
        briefApiObject.constraints.overwrite_bid_adjustments_on_device,
        briefApiObject.constraints.allow_unknown_demographic,
        briefApiObject.constraints.overwrite_frequency_mode
      ) : new ConstraintsModel()
      this.revenueType = briefApiObject.revenue_type
      this.minMargin = briefApiObject.min_margin
      this.commentBudgetMicro = briefApiObject.comment_budget_micro
      this.commentBusinessModel = briefApiObject.comment_business_model
      this.commentMarginConstraints = briefApiObject.comment_margin_constraints
      this.commentMainKpi = briefApiObject.comment_main_kpi
      this.commentMainConstraints = briefApiObject.comment_main_constraints
      this.commentViewRate = briefApiObject.comment_view_rate
      this.commentCompletionRate = briefApiObject.comment_completion_rate
      this.commentFrequencyCap = briefApiObject.comment_frequency_cap
      this.commentFrequencyTarget = briefApiObject.comment_frequency_target
      this.commentViewabilitySettings = briefApiObject.comment_viewability_settings
      this.commentBudgetRepartition = briefApiObject.comment_budget_repartition
      this.commentTimeParting = briefApiObject.comment_time_parting
      this.commentFoldPosition = briefApiObject.comment_fold_position
      this.commentCreativeActivity = briefApiObject.comment_creative_activity
      this.commentFunnel = briefApiObject.comment_funnel
      this.commentAutomaticallyRemoveDeprecatedUrl = briefApiObject.comment_automatically_remove_deprecated_url
      this.commentOverwriteBidAdjustmentsOnDevice = briefApiObject.comment_overwrite_bid_adjustments_on_device
      this.maxCPC = briefApiObject.max_CPC
      this.maxCPCV = briefApiObject.max_CPCV
      this.currency = briefApiObject.currency
      this.boostTargetUnit = briefApiObject.boost_target_unit
      this.notes = briefApiObject.notes
      this.fitScoreAtSubmissionTime = briefApiObject.fit_score_at_submission_time
      this.insertionDate = briefApiObject.insertion_date
      this.swid = briefApiObject.swid
      this.contactMailCopilot = briefApiObject.contact_mail_copilot
      this.fromTemplateBriefId = briefApiObject.from_template_brief_id
    }
  }

  getAsApiArgs (): BriefApiArgs {
    const toReturn: BriefApiArgs = {
      comments: this.comments,
      conversion_measurement_tool: this.conversionMeasurementTool,
      KPI: this.KPI,
      budget_to_saturate_in_priority: this.budgetToSaturateInPriority,
      constraints: this.constraints.getAsApiArgs(),
      line_item_budget_restrictions: this.lineItemBudgetRestrictions,
      min_cpm: this.minCpm,
      max_cpm: this.maxCpm,
      rev_CPM: this.revCPM,
      text: this.text,
      boost_target: this.boostTarget,
      boost_target_unit: this.boostTargetUnit,
      comment_budget_micro: this.commentBudgetMicro,
      comment_business_model: this.commentBusinessModel,
      comment_main_constraints: this.commentMainConstraints,
      comment_main_kpi: this.commentMainKpi,
      comment_margin_constraints: this.commentMainConstraints,
      comment_view_rate: this.commentViewRate,
      comment_completion_rate: this.commentCompletionRate,
      comment_frequency_cap: this.commentFrequencyCap,
      comment_frequency_target: this.commentFrequencyTarget,
      comment_viewability_settings: this.commentViewabilitySettings,
      comment_budget_repartition: this.commentBudgetRepartition,
      comment_time_parting: this.commentTimeParting,
      comment_fold_position: this.commentFoldPosition,
      comment_creative_activity: this.commentCreativeActivity,
      comment_funnel: this.commentFunnel,
      comment_automatically_remove_deprecated_url: this.commentAutomaticallyRemoveDeprecatedUrl,
      comment_overwrite_bid_adjustments_on_device: this.commentOverwriteBidAdjustmentsOnDevice,
      comment_allow_unknown_demographic: this.commentAllowUnknownDemographic,
      comment_predictive_clearing: this.commentPredictiveClearing,
      revenue_type: this.revenueType,
      max_CPC: this.maxCPC,
      max_CPCV: this.maxCPCV,
      currency: this.currency,
      fit_score_at_submission_time: this.fitScoreAtSubmissionTime,
      insertion_date: this.insertionDate,
      swid: this.swid,
      contact_mail_copilot: this.contactMailCopilot
    }

    let cleanedToReturn: BriefApiArgs = {}
    // rm empty value
    for (let key in toReturn) {
      if (toReturn[key as keyof BriefApiArgs] !== null &&
        toReturn[key as keyof BriefApiArgs] !== undefined &&
        toReturn[key as keyof BriefApiArgs] !== '') {
        // @ts-ignore
        cleanedToReturn[key as keyof BriefApiArgs] = toReturn[key as keyof BriefApiArgs]
      }
    }

    return cleanedToReturn
  }

  isNoteBrief () {
    return this.notes && Array.isArray(this.notes) && this.notes.length !== 0
  }

  get isAutoplugsBrief (): boolean {
    return !!this.fromTemplateBriefId
  }
}
