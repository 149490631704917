<template>
    <v-app-bar
      :height="height"
       class="topNav"
      fixed
      :class="$_VUE_ENV === 'staging' ? 'topNavStaging' : 'topNavProd'"
    >

      <v-spacer></v-spacer>

      <v-toolbar-items v-for="route in routes" v-bind:key="route.name">
        <v-btn
        v-if="$pageGuard[route.routeName] === undefined || $pageGuard[route.routeName].isDisabled[$_VUE_ENV] === false"
        @click="changeApp(route)"
        :disabled="!haveUserTheRoles(routes.roles)"
        text
        >
          {{route.name}}
        </v-btn>
      </v-toolbar-items>

      <v-toolbar-items>

        <v-btn
          text
          target="_blank"
          rel="noopener noreferrer"
          href="http://interface.sbds.io:5000/"
        >
        Interface 5000
        </v-btn>

      </v-toolbar-items>

      <v-toolbar-items>

        <v-btn
          text
          href=""
          target="_blank"
          rel="noopener noreferrer"
          class="version-info-top-nav"
        >
          V {{version}} {{$_VUE_ENV !== 'production' ? ' | ' + $_VUE_ENV : ''}}
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>
</template>

<script>
import { usersMixin } from '../../mixins/usersMixin'
export default {
  name: 'TopNav',
  props: ['version'],
  mixins: [usersMixin],
  data: function () {
    return {
      height: 40,
      routes: [
        { path: '/keystone_v2/overview', name: 'Keystone', routeName: 'keystoneV2' },
        { path: '/alertings/main', name: 'Alertings', routeName: 'alertingsMain' },
        { path: '/appnexus', name: 'Surcouche', routeName: 'table' },
        { path: '/surcoucheV2/youtube', name: 'SurcoucheV2', routeName: 'surcoucheV2' },
        { path: '/cuicui/demo/manage', name: 'Demo Manage', routeName: 'cuicuiDemoManage' },
        { path: '/users/manage', name: 'Manage users', routeName: 'userUi', roles: ['api.user.roles:get'] }
      ]
    }
  },
  methods: {
    changeApp (route) {
      if (this.$route.name === route.routeName) {
        return
      }

      if (this.$pageGuard[route.routeName] === undefined || this.$pageGuard[route.routeName].isDisabled[this.$_VUE_ENV] === false) {
        this.$emit('change-app')
        this.$changeRoute(route.path)
      }
    }
  },
  computed: {

  }
}

</script>

<style>
/*
the nav bar is colored when the env is not production for avoid errors
*/

.topNav {
  max-height: 40px;
  z-index: 1;
}

.topNav .v-toolbar__items {
  height: inherit !important;
}

.topNav div {
  font-size: 10px;
  padding: 1em;
  height: 2em;
}

.topNav span {
  font-size: 10px;
  padding: 1em;
}

.topNav span.v-btn__content {
  background: transparent !important;
}

.topNav button.v-btn.v-btn--text.theme--light.version-info-top-nav {
  cursor: default;
}

.topNavStaging div, .topNavStaging span {
  background: khaki;
}
.topNavProd div {
  background: #1976d2;
}

.topNavProd span.v-btn__content {
  color: white;
}

.topNavProd button.v-btn.v-btn--text.theme--light.version-info-top-nav {
  background: #1976d2;
}

.topNavProd button.v-btn.v-btn--text.theme--light,
.topNavProd a.v-btn.v-btn--text.theme--light {
  background: #2196f3;
}

.topNavStaging button.v-btn.v-btn--text.theme--light,
.topNavStaging a.v-btn.v-btn--text.theme--light {
  background: #fff597;
}

.topNavStaging button.v-btn.v-btn--text.theme--light.version-info-top-nav {
  background: khaki;
}

</style>
