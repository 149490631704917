/* eslint-disable no-useless-constructor */

import { ConstraintsApiArgs, OverwriteFrequencyMode } from '../../../types/brief_type'
import _ from 'lodash'
import { UnknownDemographic } from '../../../types/instruction_type'

export default class ConstraintsModel {
  constructor (public viewRate: number | string = null,
               public completionRate: number | string = null,
               public frequencyCapPeriod: string = null,
               public frequencyCapAmount: number | string = null,
               public frequencyCapExposure: number | string = null,
               public frequencyTargetPeriod: string = null,
               public overwritePredictiveClearing: boolean = false,
               public frequencyTargetAmount: number | string = null,
               public frequencyTargetExposure: number | string = null,
               public viewabilitySettings: boolean = true,
               public budgetRepartition: boolean = true,
               public timeParting: boolean = true,
               public foldPosition: boolean = true,
               public creativeActivity: boolean = true,
               public allowViewRate: boolean = false,
               public allowCompletionRate: boolean = false,
               public allowFrequencyCap: boolean = false,
               public allowFrequencyTarget: boolean = false,
               public automaticallyRemoveDeprecatedUrl: boolean = true,
               public overwriteBidAdjustmentsOnDevice: boolean = true,
               public allowUnknownDemographic: UnknownDemographic = null,
               public overwriteFrequencyMode: OverwriteFrequencyMode = null) {

  }

  getAsApiArgs (): ConstraintsApiArgs {
    const toReturn: ConstraintsApiArgs = {
      view_rate: this.viewRate,
      completion_rate: this.completionRate,
      viewability_settings: this.viewabilitySettings,
      budget_repartition: this.budgetRepartition,
      time_parting: this.timeParting,
      fold_position: this.foldPosition,
      creative_activity: this.creativeActivity,
      frequency_cap_amount: this.frequencyCapAmount,
      frequency_cap_exposure: this.frequencyCapExposure,
      frequency_cap_period: this.frequencyCapPeriod,
      frequency_target_amount: this.frequencyTargetAmount,
      frequency_target_exposure: this.frequencyTargetExposure,
      frequency_target_period: this.frequencyTargetPeriod,
      overwrite_predictive_clearing: this.overwritePredictiveClearing,
      allow_view_rate: this.allowViewRate,
      allow_completion_rate: this.allowCompletionRate,
      allow_frequency_cap: this.allowFrequencyCap,
      allow_frequency_target: this.allowFrequencyTarget,
      automatically_remove_deprecated_url: this.automaticallyRemoveDeprecatedUrl,
      overwrite_bid_adjustments_on_device: this.overwriteBidAdjustmentsOnDevice,
      allow_unknown_demographic: this.allowUnknownDemographic,
      overwrite_frequency_mode: this.overwriteFrequencyMode
    }

    for (let key in toReturn) {
      const toTest = toReturn[key as keyof ConstraintsApiArgs]
      if (!(typeof toTest === 'boolean') &&
        (_.isNil(toTest) || (typeof toTest === 'string' && _.isEmpty(toTest)))) {
        delete toReturn[key as keyof ConstraintsApiArgs]
      }
    }

    return toReturn
  }
}
