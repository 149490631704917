
import { defineComponent, PropType } from 'vue'
import ExpansionsFilters from '@/components/SurcoucheV2/ExpansionsFilters.vue'
import AutocompleteSearchBar from '../Common/AutocompleteSearchBar.vue'
import store from '../../../store'
import { surcoucheV2Store } from '../../../store/modules/surcoucheV2Store'
import {
  AutocompleteItem,
  QueryFilter, SimpleUser,
  SurcoucheV2Query
} from '../../../types/surcouche_v2_types'
import { userHexColor } from '../../../utils/commonUtils'
import { getCurrentSearchFromQuery } from '@/components/SurcoucheV2/surcoucheV2utils'
import BulkOttoComponent from '@/components/TableComponents/Form/BulkOtto/BulkOttoComponent.vue'
import { SelectedIo } from '../../../types/instruction_type'
import ActionFormV2 from '@/components/TableComponents/Form/ActionFormV2.vue'
import DecksDialog from '@/components/TableComponents/Form/DecksDialog.vue'
import BulkCsvModal from '@/components/TableComponents/Form/BulkCsv/BulkCsvModal.vue'
import { $YOUTUBE } from '../../../config/dspConfig'

// load the store module dynamically only when needed
if (!store.state.surcoucheV2Store) store.registerModule('surcoucheV2Store', surcoucheV2Store)

export default defineComponent({
  name: 'SurcoucheV2Toolbar',
  components: {
    BulkCsvModal,
    DecksDialog,
    ActionFormV2,
    BulkOttoComponent,
    ExpansionsFilters,
    AutocompleteSearchBar
  },
  props: {
    result: {
      required: false,
      type: Object as PropType<AutocompleteItem>
    },
    search: {
      required: false
    },
    users: {
      type: Array as PropType<SimpleUser[]>,
      required: false
    },
    value: {
      type: Object as any, // PropType<QueryFilter>,
      required: true
    },
    dsp: {
      type: String,
      required: true
    },
    selectedIosSync: {
      type: Object as PropType<SelectedIo>,
      required: true
    }
  },
  data: function () {
    return {
      resultSync: this.result,
      defaultUserValue: [],
      isRunning: ['On', 'Off'],
      hasAlert: ['On', 'Off'],
      isExpand: false,
      bulksEdit: [
        { title: 'Actions', value: 'bulkActions' },
        { title: 'Otto Status', value: 'bulkOttoStatus' },
        { title: 'Overwrite', value: 'bulkOverwrite' },
        { title: 'Bulk CSV', value: 'bulkCsv' }
      ],
      analytics: [
        { title: 'Insight', value: 'analyticsInsight' },
        { title: 'AB Test', value: 'analyticsABTest' },
        { title: 'Troubleshooting', value: 'analyticsTroubleshooting' }
      ],
      dialogBulkOtto: false,
      dialogAction: false,
      dialogDeck: false,
      deckTab: 'insight',
      usersSearch: '',
      action: {
        retry: 1,
        fast_retry: 1,
        is_active: 1,
        reactivate_otto: 1
      } as { [key: string]: number},
      isOverwriteTab: false,
      selectedIos: this.selectedIosSync,
      bulkCsvModal: false
    }
  },
  mounted: function () {
    this.defaultUserValue = null
    this.resultSync = this.result
  },
  methods: {
    actionMethod (ioIds: string[]) {
      let bucketsIdImplode = ioIds.join(',')

      let action = this.createActionObject()
      this.$emit('buckets-update', bucketsIdImplode, action)
      // set the state of the app
      this.$store.commit('setAppState', 'HAVE_JUST_DONE_BULK')
      this.closeAction()
      // reset value with default value
      this.resetActionValues()
    },
    createActionObject () {
      let actionObject = {} as { [key: string]: boolean }
      for (let key in this.action) {
        if (this.action[key] === 1) {
          continue
        }

        actionObject[key] = this.action[key] !== 0
      }

      return actionObject
    },
    openDeck () {
      this.dialogDeck = true
    },
    closeDeck () {
      this.dialogDeck = false
      this.deckTab = 'insight'
      this.resetActionData()
    },
    success (message: string) {
      this.$emit('ask-snackbar', message, 'success')
    },

    error (message: string) {
      this.$emit('ask-snackbar', message, 'error')
    },

    warning (message: string) {
      this.$emit('ask-snackbar', message, 'warning')
    },
    openAction () {
      this.dialogAction = true
    },
    closeAction () {
      this.dialogAction = false
      this.isOverwriteTab = false
      this.resetActionData()
    },
    resetActionData () {
      this.action = {
        retry: 1,
        fast_retry: 1,
        is_active: 1,
        reactivate_otto: 1
      }
    },
    resetActionValues () {
      // reset value with default value
      this.resetActionData()
    },
    setUserColor (user: string): string {
      if (user === undefined || !user[0]) {
        return userHexColor('All')
      }
      return userHexColor(user[0])
    },
    onSelect (data: string) {
      this.defaultUserValue.push(data)
      this.$emit('userSelected', this.defaultUserValue)
    },
    getItem (item: AutocompleteItem) {
      this.resultSync = item
    },
    reloadPage () {
      const { autocomplete, ...data } = this.value as QueryFilter
      if (autocomplete) {
        data.search = String(autocomplete?.external_id)
      }
      const filtersQuery = getCurrentSearchFromQuery(data)
      this.$emit('refresh-data', filtersQuery)
    },
    searchDeleted () {
      (this.value as QueryFilter).search = ''; // reset search value so it starts over
      (this.value as QueryFilter).autocomplete = null // reset autocomplete value so it starts over
      this.$emit('input', { ...this.value as QueryFilter })
    },
    emitInputFilter (key: keyof QueryFilter, value: string | number | string[] | AutocompleteItem) {
      this.$emit('input', { ...this.value as QueryFilter, [key]: value })
    },
    emitInputFull (event: SurcoucheV2Query) {
      console.warn('event', event)
      this.$emit('input', event)
    },
    setAvatarValue (users: string[]): string {
      if (!users || users.length === 0) {
        return 'All'
      } else {
        if (!this.users) {
          return
        }
        const nbUsers = users?.length
        const firstUser = users[0]

        if (nbUsers === 1) {
          return this.users.find(user => user.mail === firstUser).mail[0].toUpperCase()
        } else if (nbUsers > 1) {
          return `${this.users.find(user => user.mail === firstUser).mail[0].toUpperCase()} +${(nbUsers - 1)}`
        }
      }
    },
    setSelectedUsersMail (users: string[]) {
      if (!users || users.length === 0) {
        return 'All'
      } else {
        return users.length !== 1 ? users.join(', ') : users[0]
      }
    },
    toggleDialog (type: string) {
      switch (type) {
        case 'bulkOttoStatus':
          this.dialogBulkOtto = !this.dialogBulkOtto
          break
        case 'bulkActions':
          if (this.dialogAction) {
            this.closeAction()
          } else {
            this.openAction()
          }
          break
        case 'bulkOverwrite':
          if (this.dialogAction) {
            this.closeAction()
          } else {
            this.isOverwriteTab = true
            this.openAction()
          }
          break
        case 'analyticsInsight':
          if (this.dialogDeck) {
            this.closeDeck()
          } else {
            this.deckTab = 'insight'
            this.openDeck()
          }
          break
        case 'analyticsABTest':
          if (this.dialogDeck) {
            this.closeDeck()
          } else {
            this.deckTab = 'abtest'
            this.openDeck()
          }
          break
        case 'analyticsTroubleshooting':
          if (this.dialogDeck) {
            this.closeDeck()
          } else {
            this.deckTab = 'troubleshooting'
            this.openDeck()
          }
          break
        case 'bulkCsv':
          this.bulkCsvModal = !this.bulkCsvModal
          break
        default:
          console.warn('Error modale name type !')
      }
    },
    removeIoFromSelectedIos (ioId: string) {
      this.$delete(this.selectedIos, ioId)
    },
    isDisabledBulkEdit (type: string) {
      if (type === 'bulkCsv') {
        return this.$route.params.dsp !== $YOUTUBE
      } else {
        return false
      }
    }
  },
  computed: {
    tabValue: function () : string {
      return this.isOverwriteTab ? 'overwrite' : 'action'
    },
    selectedIoList: function (): string[] {
      return Object.keys(this.selectedIos).filter((key: string) => {
        return this.selectedIos[key].state === true
      })
    },
    usersList: function (): SimpleUser[] {
      if (!this.users) {
        return []
      }
      return this.users.filter((user: SimpleUser) => {
        return user.mail.includes(this.usersSearch)
      })
    }
  },
  watch: {
    isExpand (bool : boolean) {
      this.$emit('expand-filter', bool)
    },
    dsp (dsp) {
      this.emitInputFull({})
    },
    result (autocompleteResult) {
      this.resultSync = autocompleteResult
      this.emitInputFilter('autocomplete', autocompleteResult)
    },
    resultSync (autocompleteResult) {
      this.$emit('update:result', autocompleteResult)
    },
    selectedIosSync () {
      this.selectedIos = this.selectedIosSync
    },
    selectedIos (ioList: SelectedIo) {
      this.$emit('update:selectedIosSync', ioList)
    }
  }
})
