
import { defineComponent } from 'vue'
import IOPagePerformancePacing from '@/components/IOPage/PerformanceTab/IOPagePerformancePacing.vue'
import IOPagePerformanceKpiToOptimize from '@/components/IOPage/PerformanceTab/IOPagePerformanceKpiToOptimize.vue'
import IOPagePerformanceSecondaryKpi from '@/components/IOPage/PerformanceTab/IOPagePerformanceSecondaryKpi.vue'
import IOPagePerformanceConstraints from '@/components/IOPage/PerformanceTab/IOPagePerformanceConstraints.vue'
import IOPagePerformanceImpressionsAndCurrency from '@/components/IOPage/PerformanceTab/IOPagePerformanceImpressionsAndCurrency.vue'

export default defineComponent({
  name: 'IOPagePerformance',
  components: {
    IOPagePerformanceImpressionsAndCurrency,
    IOPagePerformanceConstraints,
    IOPagePerformanceSecondaryKpi,
    IOPagePerformanceKpiToOptimize,
    IOPagePerformancePacing },
  props: ['ioDatastore', 'overview', 'dsp', 'performance', 'loading'],
  data () {
    return {
      period: 'day',
      items: [
        { title: 'Yesterday', value: 'day' },
        { title: 'Last 7 days', value: 'week' },
        { title: 'Since beginning of flight', value: 'flight' }
      ]
    }
  },
  methods: {
    setPeriod (period: string) {
      this.period = period
    },
    getPeriod () {
      return this.period
    },
    getLocalDateString (utcStr: string): string {
      const date = new Date(utcStr)
      return `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}`
    },
    getLocalDateTimeString (utcStr: string): string {
      const date = new Date(utcStr)
      return `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}
${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`
    },
    formatNumbers (nb: number): string {
      if (nb === null || nb === undefined) {
        return 'N/A'
      }
      if (nb < 1000) {
        return Math.round(nb).toString()
      }
      if (nb < 1000000) {
        // if the number is a round thousand, we don't want to display the decimal
        if (Math.round(nb / 1000) === Math.round(nb * 10 / 10000)) {
          return Math.round(nb / 1000).toString() + 'K'
        }
        return (nb / 1000).toFixed(1) + 'K'
      }
      if (nb < 1000000000) {
        // if the number is a round million, we don't want to display the decimal
        if (Math.round(nb / 1000000) === Math.round(nb * 100 / 100000000)) {
          return Math.round(nb / 1000000).toString() + 'M'
        }
        return (nb / 1000000).toFixed(2) + 'M'
      }
      if (nb >= 1000000000) {
        // if the number is a round billion, we don't want to display the decimal
        if (Math.round(nb / 1000000000) === Math.round(nb * 100 / 100000000000)) {
          return Math.round(nb / 1000000000).toString() + 'B'
        }
        return (nb / 1000000000).toFixed(2) + 'B'
      }
    }
  },
  computed: {
    getInfo (): TextValueType<string>[] {
      return [
        {
          text: 'Yesterday',
          value: (this.performance?.kpi?.yesterday) ? `from "${this.getLocalDateTimeString(this.performance.kpi.yesterday.start_period_tz)}" to "${this.getLocalDateTimeString(this.performance.kpi.yesterday.end_period_tz)}"` : 'N/A'
        },
        {
          text: 'Yesterday minus 1 (comparison)',
          value: (this.performance?.kpi?.yesterday_minus_1) ? `from "${this.getLocalDateTimeString(this.performance.kpi.yesterday_minus_1.start_period_tz)}" to "${this.getLocalDateTimeString(this.performance.kpi.yesterday_minus_1.end_period_tz)}"` : 'N/A'
        },
        {
          text: 'Last 7 days',
          value: (this.performance?.kpi?.last_7_days) ? `from "${this.getLocalDateTimeString(this.performance.kpi.last_7_days.start_period_tz)}" to "${this.getLocalDateTimeString(this.performance.kpi.last_7_days.end_period_tz)}"` : 'N/A'
        },
        {
          text: 'Last 7 days minus 1 (comparison)',
          value: (this.performance?.kpi?.last_7_days_minus_1) ? `from "${this.getLocalDateTimeString(this.performance.kpi.last_7_days_minus_1.start_period_tz)}" to "${this.getLocalDateTimeString(this.performance.kpi.last_7_days_minus_1.end_period_tz)}"` : 'N/A'
        },
        {
          text: 'Since beginning of flight',
          value: (this.performance?.kpi?.flight) ? `from "${this.performance.kpi.flight.start_period_tz}" to "${this.performance.kpi.flight.end_period_tz}"` : 'N/A'
        },
        {
          text: 'Since beginning of flight minus 1 (comparison)',
          value: (this.performance?.kpi?.flight_minus_1) ? `from "${this.performance.kpi.flight_minus_1.start_period_tz}" to "${this.performance.kpi.flight_minus_1.end_period_tz}"` : 'N/A'
        }
      ]
    },
    getLastDataUpdate (): string {
      return this.performance?.kpi?.yesterday?.last_client_update_utc ? this.getLocalDateTimeString(this.performance?.kpi?.yesterday?.last_client_update_utc) : 'N/A'
    },
    lastDataUpdateOutdated (): boolean {
      // if the last data update is older than 24 hours, we consider it outdated
      return this.performance?.kpi?.yesterday?.last_client_update_utc ? new Date().getTime() - new Date(this.performance?.kpi?.yesterday?.last_client_update_utc).getTime() > 24 * 60 * 60 * 1000 : false
    }
  }
})
