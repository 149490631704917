
import { defineComponent } from 'vue'
import { $YOUTUBE } from '../../../../../config/dspConfig'

type DspCheckResult = { subDsp: string | null, errors: string[] }

export default defineComponent({
  name: 'BulkCsvModal',
  props: {
    value: Boolean
  },
  data: () => ({
    file: null,
    isUploading: false,
    isSimulating: false,
    simulationErrors: [] as string[][],
    csvText: '',
    allowedDspValues: [
      $YOUTUBE
    ] as string[],
    maxNumberOfLines: 100 as Readonly<number>,
    urlTemplate: 'https://docs.google.com/spreadsheets/d/1asRSM3yQY5E6luGnDG_7r-EsWtdYk4ajYdF9tQ7ot9I/edit',
    urlDoc: 'https://scibids-k.atlassian.net/wiki/spaces/AM/pages/2352676866/.csv+to+bulk+edit+instructions'
  }),
  methods: {
    async upload () {
      this.isUploading = true

      const parsedCsv = this.parseCsv(this.csvText)
      const result = this.checkCsv(parsedCsv)
      const response = await this.$apiCaller.postBatchCsv(this.csvText, result.subDsp)

      if (this.$apiCaller.isResponseError(response)) {
        this.$store.commit('setErrorMessageWithResponse', response)
      } else {
        this.$store.commit('setSuccessMessage', 'CSV uploaded successfully')
        this.close()
      }
      this.isUploading = false
    },
    async simulateUpload () {
      this.isSimulating = true
      this.simulationErrors = []

      const parsedCsv = this.parseCsv(this.csvText)
      const result = this.checkCsv(parsedCsv)

      if (result.errors.length > 0) {
        this.simulationErrors = [result.errors]
        this.isSimulating = false
        return
      }

      const response = await this.$apiCaller.postSimulateBatchCsv(this.csvText, result.subDsp)

      if (this.$apiCaller.isResponseError(response)) {
        const errors = response?.data?.errors ? response.data.errors : response.response.data.errors
        const errorMessage = `Error when simulating the upload: ${errors}`
        this.simulationErrors = [[errorMessage]]
      } else {
        this.simulationErrors = Object.values(response.data.errors)
      }
      this.isSimulating = false
    },
    close (): void {
      this.dialog = false
      this.resetValues()
    },
    resetValues () {
      this.file = null
      this.isUploading = false
      this.isSimulating = false
      this.simulationErrors = []
      this.csvText = ''
    },
    isDisabledUpload (): boolean {
      return !this.file || this.isUploading ||
        this.isSimulating || !this.isSimulationSuccess
    },
    parseCsv (csv: string): string[][] {
      const data = csv.split('\n')
      const lines = []
      for (const line of data) {
        lines.push(line.trim().split(','))
      }
      return lines
    },
    checkCsv (csv: string[][]): DspCheckResult {
      const errors = []
      let dspValue: string | null = null
      // 0. remove empty lines
      const csvCleared = csv.filter(row => row.filter(value => value.trim() !== '').length > 0)
      // 1. check if dsp column is filled with the same value for all rows
      // a. get the number of the dsp column
      const columns = csvCleared[0]
      const dspColumnIndex = columns.indexOf('dsp')
      if (dspColumnIndex === -1) {
        errors.push('dsp column is missing')
      } else {
        // b. check if all rows have the same value for the dsp column
        let dspValues = csvCleared.map(row => row[dspColumnIndex])
        dspValues.shift()
        dspValue = dspValues[0]

        if (!this.allowedDspValues.includes(dspValue)) {
          errors.push(`dsp column has invalid value. Allowed values are ${this.allowedDspValues.join(', ')}`)
        } else if (dspValues.some(value => value !== dspValue)) {
          const valueError = dspValues.find(value => value !== dspValue)
          const valueIndex = dspValues.indexOf(valueError)
          const columnNumber = valueIndex + 2 // +2 because we have to count the header row and the index is 0-based
          const errorMessageValueShouldBeTheSame = `dsp column has different values. All dsp values should be the same.
          The value should be ${dspValue}. Reveived value: ${valueError}. Column number: ${columnNumber}`
          errors.push(errorMessageValueShouldBeTheSame)
        }
      }

      // 2. check the max number of columns
      const nbLines = csvCleared.length

      if (nbLines > this.maxNumberOfLines) {
        errors.push(`The number of lines exceeds the maximum allowed number of columns: ${this.maxNumberOfLines}`)
      }

      return {
        subDsp: dspValue,
        errors: errors
      }
    }
  },
  computed: {
    dialog: {
      get (): boolean {
        return this.value
      },
      set (value: boolean) {
        this.$emit('input', value)
      }
    },
    isLoading (): boolean {
      return this.isUploading || this.isSimulating
    },
    isSimulationSuccess (): boolean {
      return this.simulationErrors.length === 0
    }
  },
  watch: {
    file: {
      async handler () {
        this.csvText = await this.file.text()
        this.simulateUpload()
      }
    }
  }
})
