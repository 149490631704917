import { CommitStateType } from '../../types/surcouche_v2_types'

const state = {
  loadingIoPage: false,
  loading: false,
  ioDatastore: {} as any,
  overview: {},
  performance: {},
  loadingOverview: false,
  loadingPerformance: false
}

const actions = {
  async callApi ({ commit, state }: CommitStateType, args: any): Promise<void> {
  }

}

const mutations = {
  setLoadingIoPage (state: any, load: boolean): void {
    state.loadingIoPage = load
  },
  setIoDatastore (state: any, data: any): void {
    state.ioDatastore = data
  },
  setOverview (state: any, data: any): void {
    state.overview = data
  },
  setPerformance (state: any, data: any): void {
    state.performance = data
  },
  setLoadingOverview (state: any, load: boolean): void {
    state.loadingOverview = load
  },
  setLoadingPerformance (state: any, load: boolean): void {
    state.loadingPerformance = load
  }
}

const getters = {
  getLoadingIoPage (state: any): boolean {
    return state.loadingIoPage
  },
  getIoDatastore (state: any): any {
    return state.ioDatastore
  },
  getOverview (state: any): any {
    return state.overview
  },
  getLoadingOverview (state: any): boolean {
    return state.loadingOverview
  },
  getPerformance (state: any): any {
    return state.performance
  },
  getLoadingPerformance (state: any): boolean {
    return state.loadingPerformance
  }
}

export const ioPage = {
  state,
  actions,
  mutations,
  getters
}
