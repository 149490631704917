
import { defineComponent } from 'vue'
import _ from 'lodash'
import { isNotNullOrUndefined } from '../../../../utils/commonUtils'

export default defineComponent({
  name: 'IOPagePerformancePacing',
  props: ['performance', 'ioDatastore', 'period'],
  data () {
    return {
      ioId: this.$route.params.external_id
    }
  },
  methods: {
    parseDate (date: string): number {
      const dateObj = new Date(date)
      dateObj.setHours(0, 0, 0, 0)
      return dateObj.getTime()
    },
    formatDate (dateStr: string): string {
      const date = new Date(dateStr)
      return !isNaN(date.getMonth()) && !isNaN(date.getDate()) ? `${date.getMonth() + 1}/${date.getDate()}` : 'No date'
    },
    areDateStringsSameDate (dateStr1: string, dateStr2: string) {
      return this.parseDate(dateStr1) === this.parseDate(dateStr2)
    },
    shortenPacingStatus (status: string): string {
      if (!isNotNullOrUndefined(status)) {
        return 'N/A'
      }
      return status === 'Delivering' ? 'Deliv.' : status === 'Underdelivery' ? 'Under.' : 'Over.'
    },
    getPacingForDate (date: string): any {
      return this.pacingSortedByDate.find((pacing: any) => this.areDateStringsSameDate(pacing.day_tz, date))
    },
    getPacingStatusForDate (date: string): string {
      const pacing = this.getPacingForDate(date)
      return pacing ? `${this.shortenPacingStatus(pacing.pacing_status)} - ${Math.round((1 - pacing.io_satu) * 100)}%` : 'N/A'
    },
    getPacingClassForDate (date: string): string {
      const pacing = this.getPacingForDate(date)
      if (isNotNullOrUndefined(pacing)) {
        const status = pacing.pacing_status
        return status === 'Delivering' ? 'delivering' : status === 'Underdelivery' ? 'underdelivery' : 'overdelivery'
      }
      return ''
    }
  },
  computed: {
    periodIsDaily (): boolean {
      return this.period === 'day'
    },
    periodIsWeekly (): boolean {
      return this.period === 'week'
    },
    periodIsFlight (): boolean {
      return this.period === 'flight'
    },
    pacingSortedByDate (): any {
      const pacing = _.cloneDeep(this.performance?.pacing)
      return pacing ? pacing?.sort((a: any, b: any) => this.parseDate(b.day_tz) - this.parseDate(a.day_tz)) : []
    },
    dailyPacing (): any {
      // get latest pacing only if latest pacing date is yesterday
      const yesterday = new Date()
      yesterday.setDate(yesterday.getDate() - 1)
      const latest = this.pacingSortedByDate[0]
      if (this.parseDate(latest?.day_tz) === this.parseDate(yesterday.toDateString())) {
        return `${Math.round((1 - latest?.io_satu) * 100)}%`
      }
      return `N/A`
    },
    prevDay (): any {
      const twoDaysAgo = new Date()
      twoDaysAgo.setDate(twoDaysAgo.getDate() - 2)
      // get previous day by finding the day before yesterday
      const prev = this.pacingSortedByDate.find((pacing: any) => (new Date(pacing.day_tz)).toDateString() === twoDaysAgo.toDateString())
      if (isNotNullOrUndefined(prev)) {
        return prev.day_tz
      } else {
        return twoDaysAgo.toDateString()
      }
    },
    prevDayPacing (): any {
      const twoDaysAgo = new Date()
      twoDaysAgo.setDate(twoDaysAgo.getDate() - 2)
      const prev = this.pacingSortedByDate.find((pacing: any) => (new Date(pacing.day_tz)).toDateString() === twoDaysAgo.toDateString())
      return `${Math.round((1 - prev?.io_satu) * 100)}%`
    },
    dateList (): any {
      return this.pacingSortedByDate.map((pacing: any) => pacing.day_tz)
    },
    lastSevenDaysPacing (): any {
      // get all pacings in pacingSortedByDate that are within the last 7 days
      return this.pacingSortedByDate.filter((pacing: any) => {
        const date = this.parseDate(pacing.day_tz)
        const lastSevenDays = new Date()
        lastSevenDays.setDate(lastSevenDays.getDate() - 7)
        return date >= lastSevenDays.getTime()
      })
    },
    lastSevenDaysDates (): any {
      // array of dates of the last 7 days
      const lastSevenDays = []
      for (let i = 0; i < 7; i++) {
        const date = new Date()
        date.setDate(date.getDate() - i)
        lastSevenDays.push(date.toDateString())
      }
      return lastSevenDays
    },
    pacingsOfFlight (): any {
      const start = this.parseDate(this.performance?.kpi?.flight?.start_period_tz)
      const end = this.parseDate(this.performance?.kpi?.flight?.end_period_tz)
      return this.pacingSortedByDate.filter((pacing: any) => this.parseDate(pacing.day_tz) >= start && this.parseDate(pacing.day_tz) <= end)
    },
    averageFlightPacing (): any {
      const pacing = this.pacingsOfFlight
      // sum of all io_spent / sum of all io_obj
      const sumSpent = pacing.reduce((acc: number, curr: any) => acc + curr.io_spent, 0)
      const sumBudget = pacing.reduce((acc: number, curr: any) => acc + curr.io_obj, 0)
      return `${Math.floor((sumSpent / sumBudget) * 100)}%`
    },
    numberOfDaysDelivering (): any {
      return this.pacingsOfFlight.filter((pacing: any) => pacing.pacing_status === 'Delivering').length
    },
    numberOfDaysUnderdelivering (): any {
      return this.pacingsOfFlight.filter((pacing: any) => pacing.pacing_status === 'Underdelivery').length
    },
    numberOfDaysOverdelivering (): any {
      return this.pacingsOfFlight.filter((pacing: any) => pacing.pacing_status === 'Overdelivery').length
    },
    dashboardLink (): any {
      let dates = ''
      if (this.periodIsDaily) {
        dates = 'yesterday'
        // Day+Tz+Date+%282%29=yesterday
      }
      if (this.periodIsWeekly) {
        dates = '7+day'
        // Day+Tz+Date+%282%29=7+day
      }
      if (this.periodIsFlight) {
        const start = new Date(this.performance?.kpi?.flight?.start_period_tz)
        const end = new Date(this.performance?.kpi?.flight?.end_period_tz)
        dates = `${start.getFullYear()}%2F${start.getMonth()}%2F${start.getDate()}+to+${end.getFullYear()}%2F${end.getMonth()}%2F${end.getDay()}`
        // Day+Tz+Date+%282%29=2024%2F11%2F10+to+2024%2F12%2F11
      }
      if (this.pacingSortedByDate[0]?.type_budget === 'imp') {
        // impression dashboard
        return `https://scibids.cloud.looker.com/dashboards/653?Yesterday%27s+pacing=last+day&Line+Item+ID=&Insertion+Order+ID=${this.ioId}&Hour+%26+Timestamp+%28TZ%29+Time=4+day&CUICUI+Status=LIVE&Type+Budget=imp&Day+Tz+Date+%282%29=${dates}`
      }
      // money dashboard
      return `https://scibids.cloud.looker.com/dashboards/632?Yesterday%27s+pacing=last+day&Line+Item+ID=&Insertion+Order+ID=${this.ioId}&CUICUI+Status=LIVE&Type+Budget=money&Day+Tz+Date=${dates}&Last+4+days+pacing=4+day`
    }
  }
})
